@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

@font-face {
    font-family: "Minecraft";
    src: url('/deluxemenus-editor/minecraft-font.ttf');
  }

html {
  padding-left: 10px;
  box-sizing: border-box;
}

body {
  font-family: 'Minecraft', monospace;
  background: #ccc;
  box-sizing: border-box;
  width: 100%;
  height: 80vh;
}
.ace_gutter {
  z-index: 0;
}
#ace-editor {
  height: 100% !important;
  width: 100% !important;
}
#output {
  float: right;
  width: 50vw;
  height: 95vh;
}

#itemsSearch {
  display: flex;
  flex-wrap: wrap;
}

.value {
  margin-top: 10px;
}

.content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
button:focus {outline:0}

input, textarea, button {
  font-family: 'Minecraft';
  background-color: #ccc;
  box-shadow: inset -1px -1px 0px #555555;
  -webkit-box-shadow: inset -1px -1px 0px #555555;
  -webkit-appearance: none;
}

input, textarea {
  padding: 5px;
  margin-top: 3px;
}

button {
  height: max-content;
  box-sizing: border-box;
  padding: 6px;
  cursor: pointer;
  margin: 2px;
  flex-shrink: 2;
}

.download {
  width: -webkit-fill-available;
}

.inventory {
  width: 335px;
  height: fit-content;
  background: #c6c6c6;
  border-radius: 3px;
  box-shadow: 5px 5px 0px #555555, inset 4px 4px 0px #fefefe;
  -webkit-box-shadow: 5px 5px 0px #555555, inset 4px 4px 0px #fefefe;
  -webkit-appearance: none;
  padding-top: 5px;
  padding-left: 5px;
  padding-bottom: 5px;
  margin-bottom: 10px;
}

#title {
  max-height: 16px;
  overflow: hidden;
  margin: 5px 10px;
}

.number {
  text-align: right;
  width: 22.5px;
  font-family: 'Minecraft', monospace;
  font-size: 15px;
  color: #fefefe;
  position: relative;
  text-shadow: #333 1px 2px 1px;
  top: -11px;
  right: -7px;
  cursor: default;
}

.slot {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  background: #8b8b8b;
  box-shadow: inset 1.5px 1.5px 0px rgba(55, 55, 55, 0.8),
  inset -2px -2px 0px #ffffff;
  -webkit-box-shadow: inset 1.5px 1.5px 0px rgba(55, 55, 55, 0.8),
    inset -2px -2px 0px #ffffff;
  -webkit-appearance: none;
}

.slotSpace {
  margin: 10px 10px;
  display: flex;
  flex-wrap: wrap;
}

.invisible {
  visibility: hidden;
}

.item {
  margin: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 30px;
  height: 30px;
}

.item img {
  width: 28px;
}

.ghostItem img {
  width: 28px;
}
.ghostItem .number {
  top: -20px;
  right: -13px;
}

/* ::selection {
  background: transparent;
}

::-moz-selection {
  background: transparent;
} */

.copy {
  font-family: 'Minecraft', monospace;
  font-size: 18px;
  width: 350px;
  display: flex;
  justify-content: flex-end;
  color: #333;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.selectedSlot {
  background: #C5C5C5;
}

.Dropdown-control {
  box-shadow: inset 1.5px 1.5px 0px rgba(55, 55, 55, 0.8),
    inset -2px -2px 0px #ffffff;
  -webkit-box-shadow: inset 1.5px 1.5px 0px rgba(55, 55, 55, 0.8),
    inset -2px -2px 0px #ffffff;
  -webkit-appearance: none;
  border-radius: 3px;
  background-color: unset !important;
}

.Dropdown-control:hover {
  box-shadow: inset 1.5px 1.5px 0px rgba(55, 55, 55, 0.8),
    inset -2px -2px 0px #ffffff !important;
}

.Dropdown-menu {
  background-color: #ccc !important;
}

.Dropdown-option.is-selected {
  background-color: #a5a5a5 !important;
}

.rc-tooltip-inner {
  border: 1px solid #18003D !important;
  text-shadow: 0.125em 0.125em 0 #3F3F3F;
  color: #fff !important;
  background-color: rgba(16,0,16,0.94) !important;
  min-height: unset !important;
}

.yamlOpts {
  width: 96%;
  height: 180px;
}